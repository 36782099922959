<template>
    <div>
        <div ref="render" class="results-render final-result" style="display:flex">
            <table style="font-size: 0.8rem;">
                <thead>
                    <tr class="grid-header">
                        <!--<th class="trophy" rowspan="2"></th>-->
                        <th rowspan="2" colspan="3">Compétiteur</th>
                        <th v-if="showSubTotals || showSubRanks" :colspan="(showSubTotals ? 1:0) + (showSubRanks ? 1:0)" :class='{"N/A": !hasPrecision}'>Précision</th>
                        <th v-if="showSubTotals || showSubRanks" :colspan="(showSubTotals ? 1:0) + (showSubRanks ? 1:0)" :class='{"N/A": !hasBallet}'>Ballet</th>
                        <th rowspan="2">Moyenne<br/>générale</th>
                        <th rowspan="2">Rg.</th>
                    </tr>
                    <tr class="grid-header">
                        <th v-if="showSubTotals" :class='{"N/A": !hasPrecision}'>Moy.</th>
                        <th v-if="showSubRanks" :class='{"N/A":!hasPrecision}'>Rg.</th>
                        <th v-if="showSubTotals" :class='{"N/A": !hasBallet}'>Moy.</th>
                        <th v-if="showSubRanks" :class='{"N/A": !hasBallet}'>Rg.</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="result in rows" :key="result.id" :class="{'even': (result.isEffective && (result.rowIndex % 2 == 0)), 'odd': (result.isEffective && (result.rowIndex %2 !=0)), 'non-participant': !result.isEffective }">
                        <td class="trophy" v-if="sortType=='precision_rank'">
                            <!-- Classement pour "Champion National de Précision" -->
                            <b-icon-trophy-fill v-show="true" v-if="!excludeOpens && result.isEffective && result.precision_rank < 4" :variant="result.precision_rank == 1 ? 'gold' : (result.precision_rank == 2 ? 'silver' : 'bronze')"/>
                            <b-icon-trophy-fill v-show="true" v-if="excludeOpens && result.isEffective && result.precision_federal_rank < 4" :variant="result.precision_federal_rank == 1 ? 'gold' : (result.precision_federal_rank == 2 ? 'silver' : 'bronze')"/>
                        </td>
                        <td class="trophy" v-else-if="sortType=='ballet_rank'">
                            <!-- Classement pour "Champion National de Ballet" -->
                            <b-icon-trophy-fill v-show="true" v-if="!excludeOpens && result.isEffective && result.ballet_rank < 4" :variant="result.ballet_rank == 1 ? 'gold' : (result.ballet_rank == 2 ? 'silver' : 'bronze')"/>
                            <b-icon-trophy-fill v-show="true" v-if="excludeOpens && result.isEffective && result.ballet_federal_rank < 4" :variant="result.ballet_federal_rank == 1 ? 'gold' : (result.ballet_federal_rank == 2 ? 'silver' : 'bronze')"/>
                        </td>
                        <td class="trophy" v-else>
                            <!-- Classement pour "Champion de France" -->
                            <b-icon-trophy-fill v-show="true" v-if="!excludeOpens && result.isEffective && result.rank < 4" :variant="result.rank == 1 ? 'gold' : (result.rank == 2 ? 'silver' : 'bronze')"/>
                            <b-icon-trophy-fill v-show="true" v-if="excludeOpens && result.isEffective && result.federal_rank < 4" :variant="result.federal_rank == 1 ? 'gold' : (result.federal_rank == 2 ? 'silver' : 'bronze')"/>
                        </td>

                        <td class="row-index">{{result.rowIndex}}</td>
                        <td class="name" :class="result.class">{{result.name}}</td>
                        <td v-if="result.isOutOfRanking" :class="result.outOfRankingClass" :colspan="result.colspan">{{ result.outOfRankingText }}</td>
                        
                        <td v-if="!result.isOutOfRanking && (showSubTotals || showSubRanks) && result.precision_note == null" class="N/A" :colspan="(showSubTotals ? 1:0) + (showSubRanks ? 1:0)"></td>
                        
                        <td v-if="!result.isOutOfRanking && showSubTotals && result.precision_note != null">{{ result.precision_note == null ? 'N/A' : arrondir(result.precision_note) }}</td>
                        <td v-if="!result.isOutOfRanking && showSubRanks && result.precision_note !=null">{{ result.precision_note == null ? '' : (excludeOpens ? result.precision_federal_rank : result.precision_rank) }}</td>
                        
                        
                        <td v-if="!result.isOutOfRanking && (showSubTotals || showSubRanks) && result.ballet_note == null" class="N/A" :colspan="(showSubTotals ? 1:0) + (showSubRanks ? 1:0)"></td>
                        
                        <td v-if="!result.isOutOfRanking && showSubTotals && result.ballet_note != null">{{ result.ballet_note == null ? 'N/A' : arrondir(result.ballet_note) }}</td>
                        <td v-if="!result.isOutOfRanking && showSubRanks && result.ballet_note !=null">{{ result.ballet_note == null ? '' : (excludeOpens ? result.ballet_federal_rank : result.ballet_rank) }}</td>


                        <td v-if="!result.isOutOfRanking">{{ arrondir(result.note) }}</td>
                        <td v-if="!result.isOutOfRanking"><b>{{ excludeOpens ? result.federal_rank : result.rank }}</b></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

export default{
    props: {
        Results: { type: Array, required: true},
        includePrecisionResults: { type: Boolean, default: true},
        includeBalletResults: { type: Boolean, default: true},
        includeSubTotals: { type: Boolean, default: true},
        includeSubRanks: { type: Boolean, default: true},
        excludeOpens: { type: Boolean, default: false},
        sortType: { type: String, default: 'rank'},
    },
    computed:{
        hasPrecision(){
            return this.rows ? this.rows.findIndex(r => r.precision_note != null) >= 0 : false;
        },
        hasBallet(){
            return this.rows ? this.rows.findIndex(r => r.ballet_note != null) >= 0 : false;
        },
        showSubTotals(){
            return this.includeSubTotals;
        },
        showSubRanks(){
            return this.includeSubRanks;
        },
        rows(){
            var r = this.excludeOpens ? this.Results.filter(ri => ri.isFederal) : this.Results.map(ri => ri);
            switch(this.sortType){
                case "rank":
                    r.sort((a,b) => this.compareRanks(a.note, b.note, a.rank, b.rank));
                    break;
                case "precision_rank":
                    r.sort((a,b) => this.compareRanks(a.precision_note, b.precision_note, a.precision_rank, b.precision_rank));
                    break;
                case "ballet_rank":
                    r.sort((a,b) => this.compareRanks(a.ballet_note, b.ballet_note, a.ballet_rank, b.ballet_rank));
                    break;
                case "alphabetical":
                    r.sort((a,b) => a.name.localeCompare(b.name));
                    break;
                case "subscription":
                    r.sort((a,b) => a.subscription_order - b.subscription_order);
                    break;
            }
            //console.log(r);
            return r.map((ri, i) => { ri.rowIndex = i+1; return ri; });
        }
    },
    methods:{
        compareRanks(aNote, bNote, aRank, bRank){
            var ret = 0;
           if(aNote != null && bNote != null)
                ret = aRank-bRank;
            else if(aNote != null)
                ret = -1;
            else if(bNote != null)
                ret = 0;
            //console.log('compateRanks', ret, aNote, bNote, aRank, bRank);
             return ret;
        }
    }
}
</script>

<style >
    .results-render{
        display:flex;
        justify-content: center;
    }
    .results-render > table {
        border-collapse: collapse;
        border: solid 2px var(--ffvlOrangePicto);
    }
    .results-render > table  td, .results-render > table th{
        border: solid 1px var(--ffvlOrangePicto);
        text-align: center;
    }
    table.results { margin-top: 10px;}
    .results{ border-collapse: collapse !important; }
    .results > tbody > tr > th { font-size: 0.95rem !important; orientation: landscape; border:darkgrey 1px solid !important; padding-left: 3px; padding-right: 3px; background-color:var(--ffvlBleuLogo) !important;}
    table.results > tbody > tr > td { font-size: 0.75rem !important; orientation: landscape; border:darkgrey 1px solid !important;}
    .results > tbody > tr > th.total{ background-color: var(--resultTotalHeader);}
    .results > tbody > tr > td.total{ background-color: var(--resultTotal);}
    .results > tbody > tr > th.rank{ background-color: var(--resultRankHeader);}
    .results > tbody > tr > td.rank{ background-color: var(--resultRank);}
    .results > tbody > tr > th.total, .results > tbody > tr > td.total { font-weight: bolder; color: var(--primary);}
    .results > tbody > tr > th.sorted{ background-color: var(--sortedHeader);}
    .results > tbody > tr > td.sorted{ background-color: var(--sortedCell);}

      tr.even td { background-color: beige;}
    tr.odd td{ background-color: #cdcdfd;}

    .trophy {
        background-color: transparent !important;
        border-left: none !important;
        border-right: solid 2px var(--ffvlOrangePicto) !important;
        padding-left: 5px;
        padding-right: 5px;
    }

    .row-index{ padding-left: 7px; padding-right: 7px;}

    .name{ min-width:150px !important; padding-left: 2px; text-align: left !important;}
 
    .non-participant{
        background-color: #e0e0e0 !important;
        color: #808080;
        font-style: italic;
    }
    @media screen and (max-width: 767px) and (orientation: portrait) {
        .results-render{
            orientation: landscape;
        }
    }
</style>
